<template>

  <div v-if="recaptchaActive" class="inline-block pt-8">

    <div :class="{'border border-red': recaptchaError}">

      <vue-recaptcha
        class="p-2"
        ref="recaptcha"
        @verify="recaptchaVerified"
        @expired="recaptchaExpired"
        :sitekey="recaptchaSitekey"
      ></vue-recaptcha>

    </div>

    <div v-if="recaptchaError" class="font-sans font-hairline text-xs text-red mt-1">{{ recaptchaError }}</div>

  </div>

</template>

<script>

import VueRecaptcha from 'vue-recaptcha'

import { mapState, mapMutations } from 'vuex'

export default {

  name: 'Recaptcha',

  components: {

    VueRecaptcha

  },

  props: {

    data: { type: Object }

  },

  computed: {

    ...mapState(['recaptchaActive', 'recaptchaSitekey', 'recaptchaError']),

  },

  created () {

      let form = this.data.form || {};

      let settings = form.settings || {};

      this.setRecaptchaActive(settings.enable_google_recaptcha || false);

      this.setRecaptchaError(false);

      this.setRecaptchaToken(false);

  },

  methods: {

    ...mapMutations(['setRecaptchaActive', 'setRecaptchaToken', 'setRecaptchaError']),

    recaptchaVerified (token) {

      this.setRecaptchaToken(token || false);

      this.setRecaptchaError(false);

    },

    recaptchaExpired () {

      this.setRecaptchaToken(false);

    }

  }

}
</script>
